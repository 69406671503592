<template>
    <div>
        <div class="con-tainer-fluid bg-custom-footer pt-10 pb-20">
            <div class="container-standar container-res-1440 d-lg-flex flex-xs-row">
                <div class="col-lg-3 col-md-12 mb-10 text-center">
                    <img width="283px" src="@/assets/images/logo-white.png" alt="">
                    <div class="mt-10">
                      <span class="roboto font-weight-7 font-18 mb-5 text-white d-block">
                          Unduh Aplikasi :
                      </span>
                      <a href="https://play.google.com/store/apps/details?id=com.teameite.realtravel&hl=en_US" 
                        class="d-block cursor-pointer font-12 text-white font-weight-6 ln-2 mt-2 ">
                          <center><img src="@/assets/images/icon/google-play.png" alt=""></center>
                      </a>
                    </div>
                </div> 
                   <div class="col-lg-3 mb-10 col-md-12 mb-10 ">
                     <span class="roboto font-weight-7 mb-5 font-18 text-white d-block w-100">
                        Alamat Kami: :
                    </span>
                    <span class="d-block font-12 text-white font-weight-6 ln-2 ">
                    Jakarta : Rukan Bisnis Park Kirana Cawang, Jl.DI Pandjaitan Kav. 48 Blok A-5 Kebonnanas Cipinang Cempedak-Jatinegara-Jakarta Timur
                    </span>
                     <span class="d-block font-12 mt-2 text-white font-weight-6 ln-1.5 ">
                       <i class="fa fa-envelope-o"></i>  realtravel08@gmail.com
                    </span>
                     <span class="d-block font-12 mt-2 text-white font-weight-6 ln-1.5 ">
                       <i class="fa fa-phone"></i> 021-29067433
                    </span>
                    <ul class="col-md-12 flex pl-0 mt-2">
                        <li class="m-2"><a href="https://www.facebook.com/realtravelumroh/" target="_blank"><img src="@/assets/images/icon/fb.png" alt=""></a></li> 
                         <li class="m-2"><a href="https://www.instagram.com/realtravel.official/" target="_blank"><img src="@/assets/images/icon/ig.png" alt=""></a></li> 
                           <li class="m-2"><a href="https://twitter.com/Realtravelid" target="_blank"><img src="@/assets/images/icon/tw.png" alt=""></a></li> 
                           
                           <li class="m-2"><a href="https://www.youtube.com/channel/UC-0ox4YeGyl1QML_Ejhbjaw" target="_blank"><img src="@/assets/images/icon/yt.png" alt=""></a></li> 
                    </ul>
                </div> 
                <div class="col-lg-3 col-md-12 mb-20 hide-mobile">
                    <span class="roboto font-weight-7 font-18 mb-5 text-white d-block w-100">
                        Tentang Perusahaan :
                    </span>
                    <ul class="col-lg-10 width-100 col-md-12  mb-10  font-12 roboto font-weight-6 text-white ul-footer"> 
                        <li><a href="#" @click="clickUrlFooter('/tentang-kami')">Tentang Kami</a></li>
                        <li><a href="#" @click="clickUrlFooter('/peluang-usaha')">Peluang Usaha</a></li>
                        <li><a href="#" @click="clickUrlFooter('/berita')">Video</a></li>
                        <li><a href="https://blog.realtravel.co.id">Blog</a></li>
                        <li><a href="#">FAQ</a></li>
                        <li><a href="#" @click="clickUrlFooter('/panduan-umroh')">Panduan Ibadah</a></li> 
                        <li><a href="#">Syarat & Ketentuan</a></li> 
                    </ul>
                    
                </div>
             
                  <div class="col-lg-3 col-md-12 mb-10 set-resp hide-mobile">
                    <span class="roboto font-weight-7 font-18 mb-5 text-white d-block">
                        Produk :
                    </span>
                    <ul class="col-lg-10 width-100 col-md-12  mb-10  font-12 roboto font-weight-6 text-white ul-footer"> 
                        <li><a href="#" @click="clickUrlFooter('/paket-umroh')">Paket Umroh</a></li>
                        <li><a href="#" @click="clickUrlFooter('/haji/Haji-Khusus-Kuota-Resmi')">Haji Kouta Resmi</a></li>
                        <li><a href="#" @click="clickUrlFooter('/tabungan')">Tabungan Umroh</a></li>
                        <li><a href="#" @click="clickUrlFooter('/list-all-tour')">Paket Wisata</a></li> 
                        <li><a href="#" @click="clickUrlFooter('/visa')">Visa</a></li>
                        <li><a href="/">Tiket Pesawat</a></li>
                        <li><a href="/">Hotel</a></li> 
                    </ul>
                </div>
            </div> 
        </div>
        <div class="col-lg-12 col-md-12 text-center  margin-auto pt-2 pb-2  bg-white">
            <span class="text-primary roboto font-weight-6">Hak Cipta ©  Real Travel All Rights Reserved 2019</span>
        </div>

        <b-modal id="modal-cabang" hide-footer title="Pilih Cabang Terdekat Anda">
            <div class="form-group text-center">
                <v-select
                    class="caret-icon"
                    name="selectCabang"  
                    :options="sortCabang"  
                    label="text"   
                    v-model="selectCabang"
                    placeholder="Pilih Cabang"
                ></v-select>
            </div>
            
            <div class="form-group mt-34 text-center">
              <button
                class="btn pad-10 mb-15 d-block margin-auto font-weight-6 font-16 bg-warning width-80 text-white"
                @click.prevent="submitCabang()"
              >Simpan</button>
            </div>
        </b-modal>

    </div>
</template>
<script>
    // import cookie from "js-cookie";
    export default {
        data() {
            return {
                selectCabang:'',  
                cabangData: '',          
                sortCabang:[
                    { "id": 11, text: 'Cabang Bandung', live_chat_id: 51300 },
                    { "id": 6, text: 'Cabang Banten', live_chat_id: '' },
                    { "id": 2, text: 'Cabang Jakarta', live_chat_id: 67407 },
                    { "id": 7, text: 'Cabang Jepara', live_chat_id: '' },
                    { "id": 9, text: 'Cabang Lampung', live_chat_id: '' },
                    { "id": 3, text: 'Cabang Makassar', live_chat_id: 69063  },
                    { "id": 4, text: 'Cabang Maros', live_chat_id: '' },
                    { "id": 8, text: 'Cabang Palembang', live_chat_id: ''  },
                    { "id": 5, text: 'Cabang Pekanbaru', live_chat_id: 69069 },
                    { "id": 10, text: 'Cabang Polewali Mandar', live_chat_id: 69071 },
                ],
            };
        },
        mounted() {
            this.getCabangData()
        },
        methods: {      
            getCabangData(){
                this.$store.commit("SET_BG", false);
                let recaptchaScript = document.createElement('script')
                recaptchaScript.setAttribute('src', 'https://cdn.widgetwhats.com/script.min.js')

                this.cabangData = JSON.parse(localStorage.getItem('cabang_data'));
                if (this.cabangData == null) {
                    this.$bvModal.show("modal-cabang");
                } else {
                    if (this.cabangData.live_chat_id != '') {
                        recaptchaScript.setAttribute('data-id', this.cabangData.live_chat_id)                  
                    } else {
                        recaptchaScript.setAttribute('data-id', 67407)
                    }
                }
                document.head.append(recaptchaScript)
            },
            submitCabang(){
                let cabangData = this.selectCabang;
                localStorage.setItem("cabang_data", JSON.stringify(cabangData));
                this.$bvModal.hide("modal-cabang");
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            },
            clickUrlFooter(val){
                this.$router.push(val)
            }
        }
    }
</script>