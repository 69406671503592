<template>
  <div style="background: white" class="fixed-content">
    <div class="comtainer-fluid bg-custom pt-1 pb-6">
      <div class="container-nav-saik">
        <div class="img-logo-header">
          <a href="https://realtravel.co.id">
            <img src="@/assets/images/logo.png" alt />
          </a>
        </div>

        <b-nav-item-dropdown class="nav-top-borderless bantuan-res" right>
          <template v-slot:button-content>
            <span class="btn-top">
              <span class="img-top-img">
                <img src="@/assets/images/icon/icon-question.png" alt />
              </span>
              Cabang Terdekat
            </span>
          </template>
          <!-- <b-dropdown-item href="https://wa.me/6287823237339" target="_blank"><i class="fa fa-whatsapp"></i> Area Jakarta</b-dropdown-item>
          <b-dropdown-item href="https://wa.me/628114441974" target="_blank"><i class="fa fa-whatsapp"></i> Area Makassar</b-dropdown-item>
          <b-dropdown-item href="https://wa.me/6282172549765" target="_blank"><i class="fa fa-whatsapp"></i> Area Pekanbaru</b-dropdown-item>
          <b-dropdown-item href="https://wa.me/6281807333444" target="_blank"><i class="fa fa-whatsapp"></i> Area Banten</b-dropdown-item>
          <b-dropdown-item href="https://wa.me/6282323288211" target="_blank"><i class="fa fa-whatsapp"></i> Area Jepara</b-dropdown-item>
          <b-dropdown-item href="https://wa.me/6289513942771" target="_blank"><i class="fa fa-whatsapp"></i> Area Bandung</b-dropdown-item>
          <b-dropdown-item href="https://wa.me/6285222634008" target="_blank"><i class="fa fa-whatsapp"></i> Area Subang</b-dropdown-item>
          <b-dropdown-item href="https://wa.me/6282177821200" target="_blank"><i class="fa fa-whatsapp"></i> Area Lampung</b-dropdown-item>
          <b-dropdown-item href="https://wa.me/6285268749242" target="_blank"><i class="fa fa-whatsapp"></i> Area Palembang</b-dropdown-item>
          <b-dropdown-item href="https://wa.me/628111480503" target="_blank"><i class="fa fa-whatsapp"></i> Area Maros</b-dropdown-item>
          <b-dropdown-item href="https://wa.me/628114100490" target="_blank"><i class="fa fa-whatsapp"></i> Area Polewali Mandar</b-dropdown-item>
          <b-dropdown-item href="https://wa.me/628114222212" target="_blank"><i class="fa fa-whatsapp"></i> Area Watampoe</b-dropdown-item>
          <b-dropdown-item href="https://wa.me/6285242988791" target="_blank"><i class="fa fa-whatsapp"></i> Area Siwa</b-dropdown-item> -->
          <div :key="index" v-for="(cbg, index) in sortCabang">
            <b-dropdown-item
              href="javascript:;"
              v-model="selectCabang"
              @click.prevent="clickCabang(cbg)"
              ><i class="fa fa-whatsapp"></i> {{ cbg.text }}</b-dropdown-item
            >
          </div>
        </b-nav-item-dropdown>
        <template v-if="this.$store.state.userdata !== null">
          <b-nav-item-dropdown
            class="nav-top-borderless usersetting-responsive"
            right
          >
            <template v-slot:button-content>
              <span class="user-adept">
                <span class="img-user">
                  <img :src="avatar" alt />
                </span>
                <span class="name-user hide-mobile">{{ userName }}</span>
              </span>
            </template>
            <b-dropdown-item href="#" to="/setting">Pengaturan</b-dropdown-item>
            <!-- <b-dropdown-item href="#" to="/transaksi"
              >List Transaksi</b-dropdown-item
            > -->
            <b-dropdown-item href="#" @click.prevent="logout()"
              >logout</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-navbar toggleable="lg" type="light" class="float-right ml-auto">
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          </b-navbar>
        </template>
        <template v-else>
          <b-nav-item class="nav-top mt-3 bg-primary hide-mobile" href="#">
            <b-navbar-brand
              class="mr-0 text-white pt-0 pb-0 font-14 font-weight-5"
              href="#"
              v-b-modal.modal-login
              >Login</b-navbar-brand
            >
          </b-nav-item>
          <b-nav-item
            class="nav-top ml-4 bg-warning hide-mobile"
            @click.prevent="routerRegis()"
            href="#"
          >
            <b-navbar-brand
              class="mr-0 text-white pt-0 pb-0 font-14 font-weight-5"
              href="#"
              >Register</b-navbar-brand
            >
          </b-nav-item>
          <b-navbar toggleable="lg" type="light" class="float-right no-login">
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          </b-navbar>
        </template>
      </div>
    </div>

    <div class="container-fluid shadow-responsive">
      <div class="container">
        <div class="margin-auto width-100 bottom-navigation">
          <b-navbar toggleable="lg" type="dark">
            <b-collapse id="nav-collapse" is-nav>
              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto sub-nav" right>
                <b-nav-item class href="https://realtravel.co.id">
                  <b-navbar-brand class="mr-2 ml-2">Home</b-navbar-brand>
                </b-nav-item>
                <b-nav-item
                  class
                  to="/paket-umroh"
                  :class="{
                    'router-link-isactive': subIsActive(['/detail-umroh']),
                  }"
                  exact
                >
                  <b-navbar-brand class="mr-2 ml-2">Umroh</b-navbar-brand>
                </b-nav-item>
                <b-nav-item
                  class
                  to="/visa"
                  :class="{ 'router-link-isactive': subIsActive(['/visa']) }"
                  exact
                >
                  <b-navbar-brand class="mr-2 ml-2">Visa</b-navbar-brand>
                </b-nav-item>

                <b-nav-item
                  class
                  to="/tabungan"
                  :class="{
                    'router-link-isactive': subIsActive(['/tabungan']),
                  }"
                  exact
                >
                  <b-navbar-brand class="mr-2 ml-2">Tabungan</b-navbar-brand>
                </b-nav-item>

                <b-nav-item class href="https://blog.realtravel.co.id">
                  <b-navbar-brand class="mr-2 ml-2">Blog</b-navbar-brand>
                </b-nav-item>
                <b-nav-item
                  class
                  to="/panduan-umroh"
                  :class="{
                    'router-link-isactive': subIsActive(['/doa-umroh']),
                  }"
                  exact
                >
                  <b-navbar-brand class="mr-2 ml-2"
                    >Panduan Ibadah</b-navbar-brand
                  >
                </b-nav-item>
                <b-nav-item class href="https://realtravel.co.id/tentang-kami">
                  <b-navbar-brand class="mr-2 ml-2"
                    >Tentang Kami</b-navbar-brand
                  >
                </b-nav-item>
                <b-nav-item class="d-flex d-md-none d-lg-none" href="#">
                  <b-navbar-brand
                    class="mr-2 ml-2 font-14 font-weight-5"
                    href="#"
                    v-b-modal.modal-login
                    >Login</b-navbar-brand
                  >
                </b-nav-item>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </div>
      </div>
    </div>

    <b-modal id="modal-login" hide-footer title>
      <div class="row pad-10">
        <div class="col-md-12 flex mb-40">
          <span class="d-flex w-50 font-22 text-primary font-weight-5"
            >Login</span
          >
          <span
            class="d-flex w-50 justify-end text-right mt-2 text-warning cursor-pointer font-weight-5"
            @click.prevent="routerRegis()"
            >Daftar</span
          >
        </div>
        <div class="col-md-12" v-show="EmailError">
          <span
            class="d-block text-center text-danger font-weight-6 margin-auto font-16 mb-10"
            >Maaf Email Anda Belum Terdaftar</span
          >
        </div>
        <div class="col-md-12">
          <form action="#">
            <div class="form-group">
              <label class="font-weight-2 text-primary" for="inputEmail"
                >Email address</label
              >
              <input
                type="email"
                class="form-control"
                name="emailAddress"
                v-model="email"
                v-validate="'required'"
                aria-describedby="emailHelp"
                placeholder="Masukan email anda"
              />
              <small id="emailHelp" class="form-text text-muted"
                >Exp: muammar@gmail.com</small
              >
              <span class="eror text-danger font-10">{{
                errors.first("emailAddress")
              }}</span>
            </div>
            <div class="form-group">
              <label class="font-weight-2 text-primary" for="password"
                >Password</label
              >
              <input
                type="password"
                name="password"
                class="form-control"
                v-model="password"
                v-validate="'required'"
                aria-describedby="password"
                placeholder="Masukan Password"
              />
              <span class="eror text-danger font-10">{{
                errors.first("password")
              }}</span>
            </div>
            <p class="font-12">
              Lupa Password ?
              <span
                class="font-weight-6 cursor-pointer text-warning"
                @click="forgotPassword"
                >Klik disini!</span
              >
            </p>
            <div class="form-group mt-34 text-center">
              <button
                class="btn pad-10 mb-15 d-block margin-auto font-weight-6 font-16 bg-warning width-80 text-white"
                @click.prevent="submit()"
              >
                Login
              </button>
              <span class="d-block mb-15 font-weight-5 text-primary"
                >Or login with</span
              >

              <GoogleLogin
                class="flex justify-center"
                :params="params"
                :renderParams="renderParams"
                :onSuccess="onSuccess"
              ></GoogleLogin>
            </div>
          </form>
        </div>
      </div>
    </b-modal>

    <b-modal id="modal-forgot-password" hide-footer title>
      <div class="row pad-10">
        <div class="col-md-12">
          <form action="#">
            <div class="form-group">
              <label class="font-weight-2 text-primary" for="inputEmail"
                >Email address</label
              >
              <input
                type="email"
                class="form-control"
                name="emailAddress"
                v-model="email"
                v-validate="'required'"
                aria-describedby="emailHelp"
                placeholder="Masukan email anda"
              />
              <span class="eror text-danger font-10">{{
                errors.first("emailAddress")
              }}</span>
            </div>
            <div class="form-group mt-34 text-center">
              <button
                class="btn pad-10 mb-15 d-block margin-auto font-weight-6 font-16 bg-warning width-80 text-white"
                @click.prevent="submitForgotPassword()"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import cookie from "js-cookie";
import GoogleLogin from "vue-google-login";
export default {
  name: "Header",
  components: {
    GoogleLogin,
  },
  data() {
    return {
      SubBackground: false,
      email: null,
      logoutst: false,
      params: {},
      // only needed if you want to render the button with the google ui
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
      dataProduk: null,
      password: null,
      paramBack: null,
      checkedGoogleEmail: null,
      avatar: null,
      userName: null,
      EmailError: false,
      error: false,
      queryoarsereferal: null,
      selectCabang: "",
      cabangData: "",
      sortCabang: [
        { id: 11, text: "Cabang Bandung", live_chat_id: 51300 },
        { id: 6, text: "Cabang Banten", live_chat_id: "" },
        { id: 2, text: "Cabang Jakarta", live_chat_id: 67407 },
        { id: 7, text: "Cabang Jepara", live_chat_id: "" },
        { id: 9, text: "Cabang Lampung", live_chat_id: 69069 },
        { id: 3, text: "Cabang Makassar", live_chat_id: 69063 },
        { id: 4, text: "Cabang Maros", live_chat_id: 69063 },
        { id: 8, text: "Cabang Palembang", live_chat_id: 69069 },
        { id: 5, text: "Cabang Pekanbaru", live_chat_id: 69069 },
        { id: 10, text: "Cabang Polewali Mandar", live_chat_id: 69071 },
      ],
    };
  },
  mounted() {
    this.getDataLogin();
    this.referallook();
  },
  methods: {
    referallook() {
      this.queryoarsereferal = this.$route.query;
      // new referal coondition

      if ("referal" in this.queryoarsereferal) {
        this.$router.push(
          "/register/?referal=" + this.queryoarsereferal.referal
        );
      }
    },
    onSuccess(googleUser) {
      // console.log(googleUser)
      let a = googleUser;

      let result = Object.keys(a).map(function (key) {
        return [Number(key), a[key]];
      });
      let b = result[2];
      let c = b[1];
      this.checkedGoogleEmail = c[Object.keys(c)[Object.keys(c).length - 1]];
      // console.log(this.checkedGoogleEmail);
      this.getAuthsenah();

      // This only gets the user information: id, name, imageUrl and email
    },
    getAuthsenah() {
      let param = new FormData();
      param.append("email", this.checkedGoogleEmail);
      this.axios
        .post("https://api.realtravel.co.id/user/auth", param, {
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "multipart/form-data, text/plain",
          }),
        })
        .then((response) => {
          let res = response;
          if (res.data.code == 200) {
            let userdata = res.data.response.data;
            this.$store.commit("SET_LOGIN", userdata);
            setTimeout(() => {
              this.setIDpartnerGet();
            }, 500);
          } else {
            this.$authGoogle.disconnect();
            this.EmailError = true;
            setTimeout(() => {
              this.EmailError = false;
            }, 5000);
          }
        });
    },
    // get partner Id
    setIDpartnerGet() {
      this.axios
        .get("https://api.realtravel.co.id//user/get_partner_id", {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data, text/plain",
            token: this.$store.state.userdata.token,
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
            let res = response.data.response.data;
            // console.log(res)
            let userdata = this.$store.state.userdata;
            userdata.partner_id = res[0].partner_id;
            // console.log(userdata)
            this.$store.commit("SET_LOGIN", userdata);
            cookie.set("userdata", userdata, { expires: 1 });
            this.ChekcParamLogin();
          } else {
            this.logout();
          }
        });
    },
    checkPartnerID() {
      this.axios
        .get("https://api.realtravel.co.id//user/get_partner_id", {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data, text/plain",
            token: this.$store.state.userdata.token,
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.getDataLogin();
          } else {
            this.logout();
          }
        })
        .catch((error) => {
          error;
          this.logout();
        });
    },
    // end
    // data dari Domain Lain
    getDataLogin() {
      // this.$authGoogle.disconnect()
      if (this.$store.state.userdata !== null) {
        this.avatar = this.$store.state.userdata.photo;
        this.userName = this.$store.state.userdata.name;
      }

      if ("hct" in this.$route.query) {
        // this.$bvModal.show("modal-login");
        // console.log(this.$route.query)
        this.checkPartnerID();
        if (this.$store.state.userdata !== null) {
          if ("hct" in this.$route.query) {
            this.paramBack = this.$route.query;
            if (this.paramBack.hct == "umroh") {
              this.getDataUmroh();
            } else {
              this.dataProduk = this.$route.query.hct;
              this.getDataTicket();
            }
          }
        } else {
          this.$bvModal.show("modal-login");
        }
      } else if ("login" in this.$route.query) {
        this.$bvModal.show("modal-login");
      }
    },
    //
    getDataUmroh() {
      setTimeout(() => {
        if (this.$store.state.userdata.partner_id !== "undefined") {
          window.location.assign(
            this.paramBack.url + "?hct=" + this.$store.state.userdata.token
          );
        } else {
          this.logout();
          this.$bvModal.show("modal-login");
        }
      }, 500);
    },
    getDataTicket() {
      setTimeout(() => {
        if (this.$store.state.userdata.partner_id !== "undefined") {
          window.location.assign(
            "https://ticket.realtravel.co.id/product/" +
              this.dataProduk +
              "/checkout?access_type=full&hct=" +
              this.$store.state.userdata.token
          );
        } else {
          this.logout();
          this.$bvModal.show("modal-login");
        }
      }, 500);
    },
    getData() {
      let param = new FormData();
      param.append("email", this.email);
      param.append("password", this.password);
      this.axios
        .post("https://api.realtravel.co.id/user/login", param, {
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "multipart/form-data, text/plain",
          }),
        })
        .then((response) => {
          let res = response;
          if (res.data.code == 200) {
            let userdata = res.data.response.data;
            this.$store.commit("SET_LOGIN", userdata);
            setTimeout(() => {
              this.setIDpartnerGet();
            }, 500);
          } else {
            this.$authGoogle.disconnect();
            this.EmailError = true;
            setTimeout(() => {
              this.EmailError = false;
            }, 5000);
          }
        })
        .catch((error) => {
          error;
        });
    },

    ChekcParamLogin() {
      if ("hct" in this.$route.query) {
        window.location.reload();
      } else if ("login" in this.$route.query) {
        this.$router.push("/");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        window.location.reload();
      }
    },
    submit() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.getData();
        } else {
          this.error = true;
        }
      });
    },

    forgotPassword() {
      this.$bvModal.hide("modal-login");
      this.$bvModal.show("modal-forgot-password");
    },

    submitForgotPassword() {
      let param = new FormData();
      param.append("email", this.email);
      this.axios
        .post("https://api.realtravel.co.id/user/reset_token", param)
        .then((response) => {
          let res = response.data;
          var obj = res;
          if (obj.code == 200) {
            this.$bvModal.hide("modal-forgot-password");
            this.$swal("", "Silahkan cek email anda.", "success");
          } else {
            this.$swal(
              "",
              "Email yang anda masukan belum terdaftar.",
              "warning"
            );
          }
        })
        .catch((error) => {
          error;
        });
    },

    routerRegis() {
      // location.assign('/register')
      this.$router.push("/register");
    },
    // listTransaksi(){
    //   window.location.assign('https://umroh.realtravel.co.id/transaksi?hct='+this.$store.state.userdata.token)
    // },
    pengaturan() {
      window.location.assign(
        "https://umroh.realtravel.co.id/profile?hct=" +
          this.$store.state.userdata.token
      );
    },
    logout() {
      this.$authGoogle.disconnect();
      cookie.remove("userdata");
      this.$store.commit("SET_LOGIN", null);
      this.$router.push("/");
      setTimeout(() => {
        this.$authGoogle.disconnect();
        window.location.reload();
      }, 1000);

      // this.$authGoogle.disconnect()
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    },
    clickCabang(value) {
      let cabangData = value;
      // console.log(cabangData.live_chat_id)
      localStorage.setItem("cabang_data", JSON.stringify(cabangData));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
  },
};
</script>
